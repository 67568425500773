import React, { useEffect, useState } from 'react';
import HeaderProperty from "./component/HeaderProperty";
import Footer from './component/Footer';
import { useParams } from 'react-router-dom';
import { API_URL } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faCartShopping, faMinusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useCart } from './component/CartContext';
import GoogleMap from './component/GoogleMap';

const PropertyDetail = () => {
    const { id } = useParams();
    const [property, setProperty] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('unpaidBills');
    const { cart, addToCart, removeFromCart } = useCart();
    const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

    useEffect(() => {
        fetch(`${API_URL}/property/${id}`)
            .then(response => response.json())
            .then(data => {
                setProperty(data);
                getGeocode(data.property.property_address);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching property:', error);
                setLoading(false);
            });
    }, [id]);

    const getGeocode = (address) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ 'address': address }, function(results, status) {
            if (status === 'OK') {
                setCoordinates({
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                });
            } else {
                console.error('Geocode was not successful for the following reason: ' + status);
            }
        });
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!property) {
        return <div>Property not found.</div>;
    }

    const formatCurrency = value => {
        if (value === null || value === undefined || isNaN(Number(value))) {
            return ''; // or return 'N/A' if you prefer
        }
        return Number(value).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',  // Set to 'USD' for US Dollar, adjust as necessary for other currencies
        });
    };

    const formatDate = date => {
        return date ? new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }) : 'N/A';
    };

    const areAllBillsInCart = (bills) => {
        return bills.every(bill => cart.some(item => item.id === bill.id));
    };

    const addAllToCart = (bills) => {
        bills.forEach(bill => {
            if (!cart.some(item => item.id === bill.id) && bill.balance > 0 && !bill.has_order) {
                addToCart(bill);
            }
        });
    };

    const emptyCart = (bills) => {
        bills.forEach(bill => {
            if (cart.some(item => item.id === bill.id)) {
                removeFromCart(bill.id);
            }
        });
    };

    const renderBillsTable = (bills) => {
        if (!Array.isArray(bills) || bills.length === 0) {
            return <p>No bill data available.</p>;
        }

        const totalDue = bills.reduce((acc, bill) => bill.balance > 0 ? acc + parseFloat(bill.balance) : acc, 0);
        const allInCart = areAllBillsInCart(bills); // Define 'allInCart' here

        return (
            <div className="table-responsiveness">
                <table className="table table-striped-columns">
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Owner</th>
                        <th>Municipality</th>
                        <th className="text-right">Due Date</th>
                        <th className="text-right">Due</th>
                        <th className="text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {bills.map(bill => {
                        const isInCart = cart.some(item => item.id === bill.id);  // Check if bill is in the cart
                        return (
                            <tr key={bill.id}>
                                <td>{bill.tax_year}</td>
                                <td>{bill.owner_name}</td>
                                <td>{bill.municipality_name}, {bill.state_name}</td>
                                <td>{formatDate(bill.due_date)}</td>
                                <td className="text-right">{formatCurrency(bill.balance)}</td>
                                <td className="text-right">
                                    {bill.has_order ? (
                                        <span style={{ color: 'green' }}>Order Placed</span> // Show label for placed order
                                    ) : bill.balance > 0 ? (
                                        isInCart ? (
                                            <button onClick={() => removeFromCart(bill.id)} className="remove-cart">
                                                <FontAwesomeIcon icon={faMinusCircle} />
                                            </button>
                                        ) : (
                                            <button onClick={() => addToCart(bill)}>
                                                <FontAwesomeIcon icon={faCartPlus} />
                                            </button>
                                        )
                                    ) : null}
                                </td>
                            </tr>
                        );
                    })}

                </tbody>
                {activeTab === 'unpaidBills' && bills.length > 1 && ( // Check if more than 1 unpaid bill
                    <tfoot>
                        <tr>
                            <td colSpan="2" className="text-right">Total Due</td>
                            <td className="text-right">{formatCurrency(totalDue)}</td>
                            <td colSpan="6" className="text-right add-cart">
                                <button 
                                    onClick={allInCart ? () => emptyCart(bills) : () => addAllToCart(bills)}
                                    disabled={allInCart} // Disable button if all bills are in cart
                                >
                                    {allInCart ? 'Empty Cart' : 'Add All to Cart'} 
                                    <FontAwesomeIcon icon={allInCart ? faTrashAlt : faCartPlus} />
                                </button>
                            </td>
                        </tr>
                    </tfoot>
                )}
                </table>
            </div>
        );
    };

    const renderReceipts = () => (
        <div className="white-wrapper p-30 mt-30">
            <h2 className="mt-0 pb-10">Receipts</h2>
            {property.property && property.property.receipts && property.property.receipts.length > 0 ? (
                <div className="table-responsiveness">
                    <table className="table table-striped-columns">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Tax Amount</th>
                            <th>Penalty Amount</th>
                            <th>Fee Amount</th>
                            <th>Processing Fee</th>
                            <th>Paid By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {property.property.receipts.map(receipt => (
                            <tr key={receipt.id}>
                                <td>{receipt.number}</td>
                                <td>{formatCurrency(receipt.amount)}</td>
                                <td>{formatDate(receipt.date)}</td>
                                <td>{formatCurrency(receipt.tax_amount)}</td>
                                <td>{formatCurrency(receipt.penalty_amount)}</td>
                                <td>{formatCurrency(receipt.fee_amount)}</td>
                                <td>{formatCurrency(receipt.processing_fee_amount)}</td>
                                <td>{receipt.paid_by}</td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </div>    
            ) : <p>No receipts available.</p>}
        </div>
    );

    const renderParcelDetails = () => (
        <div className="row parcel-wrap">
            <div className="col-md-12">
                <h2>Parcel Details</h2>

                <div className="mb-20">
                    <div className="google-map-container">
                        <GoogleMap lat={coordinates.lat} lng={coordinates.lng} />
                    </div>
                </div>
                <div className="box-wrap">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-6">Bill Number <span>{property.property.bill_number}</span></div>
                        <div className="col-md-6 col-sm-6 col-xs-6">Property Type <span>{property.property.property_type}</span></div>
                    </div>
                </div>
                <div className="box-wrap">
                    <div className="row">
                        <div className="col-md-12">Owner <span>{property.property.owner_name}</span></div>
                    </div>
                </div>

                <div className="box-wrap">
                    <div className="row">
                        <div className="col-md-12">Address <span>{property.property.property_address}</span></div>
                    </div>
                </div>
                <div className="box-wrap">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-6">Situs <span>{property.property.legal_description || 'N/A'}</span></div>
                        <div className="col-md-6 col-sm-6 col-xs-6">Legal Description <span>{property.property.legal_description || 'Not available'}</span></div>
                    </div>
                </div>
                <div className="box-wrap">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-6">Deed Book/Page <span>{property.property.deed_book}/{property.property.deed_page}</span></div>
                        <div className="col-md-6 col-sm-6 col-xs-6">Under Appeals <span>{property.property.under_appeals ? 'Yes' : 'No'}</span></div>
                    </div>
                </div>
                <div className="box-wrap">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-6">Bankruptcy Status <span>{property.property.is_bankruptcy ? 'Yes' : 'No'}</span></div>
                        <div className="col-md-6 col-sm-6 col-xs-6">Land Value <span>{formatCurrency(property.property.land_value)}</span></div>
                    </div>
                </div>
                <div className="box-wrap">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-6">Improvement Value <span>{formatCurrency(property.property.improvement_value)}</span></div>
                        <div className="col-md-6 col-sm-6 col-xs-6">Total Property Value <span>{formatCurrency(property.property.total_property_value)}</span></div>
                    </div>
                </div>
                <div className="box-wrap">
                    <div className="row">
                        <div className="col-md-6">Assessed Value <span>{formatCurrency(property.property.assessed_value)}</span></div>
                        <div className="col-md-6"></div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <div className="property-detail-head">
                <HeaderProperty />
            </div>
            <div className="property-detail-head-inner">
                <div className="container">
                    <h1>Account:- {property.property.account_number}</h1>
                    <h2>Tax year: {property.property.tax_year}</h2>
                </div>
                <div className="dark"></div>
            </div>
            <div className="bill-detail-wrap">
                <div className="container">
                    <div className="row">

                        <div className="col-md-8">
                            <div className="left-margin-move">
                                <div className="white-wrapper p-30">
                                    <div className="tabs">
                                        <div className="row">
                                            <div className="col-md-3"></div>
                                            <div className="col-md-6">
                                                <div className="tab-list">
                                                    <ul>
                                                        <li><a href="#" onClick={() => setActiveTab('unpaidBills')} className={activeTab === 'unpaidBills' ? 'active' : ''}>Unpaid Bills</a></li>
                                                        <li><a href="#" onClick={() => setActiveTab('allBills')} className={activeTab === 'allBills' ? 'active' : ''}>All Bills</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="tab-panel">
                                            {activeTab === 'unpaidBills' && property.unpaid_properties && renderBillsTable(property.unpaid_properties)}
                                            {activeTab === 'allBills' && property.related_properties && renderBillsTable(property.related_properties)}
                                        </div>

                                    </div>
                                </div>
                                {renderReceipts()}
                            </div>
                        </div>
                        <div className="col-md-4">
                            {renderParcelDetails()}
                        </div>
                    </div>
                </div>
            </div>
            
            
            <Footer />
        </div>
    );
};

export default PropertyDetail;

import React, { useState, useEffect } from 'react';
import HeaderProperty from './component/HeaderProperty';
import Footer from './component/Footer';
import { useCart } from './component/CartContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import PaymentFormProperty from './component/PaymentGatewayProperty';
import { useNavigate } from 'react-router-dom';
import SearchResultsDrawer from './component/SearchResultsDrawer';
import { useUI } from './component/UIContext';

const Cart = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const { toggleBackdrop } = useUI();

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { itemCount, totalDue, cart, removeFromCart } = useCart();
  const formatCurrency = (value) => `$${parseFloat(value).toFixed(2)}`;
  const formatDate = (date) => {
    return date
      ? new Date(date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : 'N/A';
  };

  const navigate = useNavigate();

  return (
    <div>
      <div className={`property-detail-head${isScrolled ? ' scrolled' : ''}`}>
        <HeaderProperty />
      </div>
      <div className="property-detail-head-inner">
        <div className="container">
          <h1>Your Cart</h1>
          <h2>
            {itemCount() === 1 ? '1 item' : `${itemCount()} items`} -{' '}
            {formatCurrency(totalDue())}
          </h2>
        </div>
        <div className="dark"></div>
      </div>
      <div className="bill-detail-wrap">
        <div className="container-fluid padding-zero">
          {cart.length > 0 ? (
            <>
              <div className="white-wrapper p-30">
                <div className="table-responsiveness">
                  <table className="table table-striped-columns">
                    <thead>
                      <tr>
                        <th>Account</th>
                        <th>Year</th>
                        <th>Bill</th>
                        <th>Due Date</th>
                        <th className="text-right">Amount</th>
                        <th>Owner Name</th>
                        <th>Municipality</th>
                        <th>State</th>
                        <th>Property Type</th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((item) => (
                        <tr key={item.id}>
                          <td>{item.account_number}</td>
                          <td>{item.tax_year}</td>
                          <td>{item.bill_number}</td>
                          <td>{formatDate(item.due_date)}</td>
                          <td className="text-right">
                            {formatCurrency(item.balance)}
                          </td>
                          <td>{item.owner_name}</td>
                          <td>{item.municipality_name}</td>
                          <td>{item.state_name}</td>
                          <td>{item.property_type}</td>
                          <td className="text-right">
                            <button onClick={() => removeFromCart(item.id)}>
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>

                            <button
                              className=""
                              onClick={() => {
                                navigate(`/property-detail/${item.id}`);
                              }}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                   <div className="text-center mt-4">
                <button
                  className="btn btn-primary"
                  onClick={() => toggleBackdrop(true)}
                >
                  Add More
                </button>
              </div>
                </div>
              </div>
              <div className="container-fluid p-40">
                <PaymentFormProperty />
              </div>
             
              <SearchResultsDrawer />
            </>
          ) : (
            <div className="white-wrapper p-30 text-center">
              <h3>Your cart is currently empty.</h3>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cart;

import React, { useState, useEffect, useCallback, useMemo  } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useCart } from './CartContext'; // Ensure this hook is correctly imported
import Swal from 'sweetalert2'; // Import SweetAlert for showing alerts
import LoginPopup from './LoginPopup';
import { useAuth } from "./AuthContext"; // Import the useAuth hook
import { useNavigate } from 'react-router-dom'; // Used for navigation after successful form submission
import InputMask from "react-input-mask";
import Loading from "./Loading";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete"; // Import PlacesAutocomplete and related functions

import {
    API_URL,
    ROUTING_NUMBER_API_URL,
    PAYMENT_GATEWAY_DEVICEID,
    TSEP_HOST,
} from "../../config";

function PaymentForm() {

    const [address, setAddress] = useState("");
    const [pinCode, setPinCode] = useState("");

    const [loading, setLoading] = useState(false);
    const [nextYearExtendedValue, setNextYearExtendedValue] = useState("");
    const [propertyOtherCharges, setPropertyOtherCharges] = useState(0);

    const navigate = useNavigate();
    const { cart, totalDue, clearCart } = useCart();
    const { user, login, logout, isAuthenticated } = useAuth();

    const [transitTokenObject, setTransitTokenObject] = useState(null);
    const [accountNumber, setAccountNumber] = useState("");
    const [repeatAccountNumber, setRepeatAccountNumber] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");
    const [accountType, setAccountType] = useState("");
    const [cardType, setCardType] = useState("");
    const [savedPaymentMethod, setSavedPaymentMethod] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("ach");
    const [bankInfo, setBankInfo] = useState(null);
    const [achAgree, setAchAgree] = useState(false);
    const [creditCardAgree, setCreditCardAgree] = useState(false);
    const [termsAgree, setTermsAgree] = useState(false);
    const [smsTermsAgree, setSmsTermsAgree] = useState(false);
    const [accountName, setAccountName] = useState("");
    const [validationError, setValidationError] = useState({});
    const [accountNumberType, setAccountNumberType] = useState('tel');
    const [repeatAccountNumberType, setRepeatAccountNumberType] = useState('tel');
    const [paymentDate, setPaymentDate] = useState("1st");
    const [emiOption, setEmiOption] = useState("full");
    const [paymentSchedule, setPaymentSchedule] = useState([]);
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [rawPhoneNumber, setRawPhoneNumber] = useState("");
    const [isLoginOpen, setLoginOpen] = useState(false);

    const [otherCharges, setOtherCharges] = useState(0);
    const [metadataLoaded, setMetadataLoaded] = useState(false);

    // Add a new state for storing payment methods
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [firstPayment, setFirstPayment] = useState([]);

    const identifiersArray = useMemo(() => [
        'cc_fee_type_property',
        'cc_fee_property',
        'ach_fee_type_property',
        'ach_fee_property',
        'next_year_extended_value',
        'property_other_charges'
    ], []);

const fetchMetaData = useCallback(() => {
    setLoading(true);
    fetch(`${API_URL}/meta-data`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ identifiers: identifiersArray }),
    })
    .then((response) => response.json())
    .then((data) => {
        data.forEach(item => {
            switch (item.identifier) {
                case 'next_year_extended_value':
                    setNextYearExtendedValue(item.data);
                    break;
                case 'property_other_charges':
                    setPropertyOtherCharges(parseFloat(item.data || 0));
                    break;
                default:
                    break;
            }
        });
        setMetadataLoaded(true);
        setLoading(false);
    })
    .catch((error) => {
        console.error("Error fetching metadata:", error);
        setLoading(false);
    });
}, [identifiersArray]);



    const fetchPaymentMethods = useCallback(() => {
        if (!isAuthenticated) return;

        setLoading(true);
        fetch(`${API_URL}/user/payment-methods`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`, // Adjust the token retrieval as necessary
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setPaymentMethods(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching payment methods:", error);
                setLoading(false);
            });
    }, [isAuthenticated]);

    useEffect(() => {
        fetchMetaData();
        if (isAuthenticated) {
            fetchPaymentMethods();
        }
    }, [fetchMetaData, fetchPaymentMethods, isAuthenticated]);

    const calculateOtherCharges = useCallback(() => {
        let additionalCharges = propertyOtherCharges;

        const uniqueAccountNumbers = [...new Set(cart.map(item => item.account_number))];
        const uniqueCount = uniqueAccountNumbers.length;

        additionalCharges *= uniqueCount;

        if (emiOption === 'full') {
            setOtherCharges(parseFloat(totalDue()) + parseFloat(additionalCharges));
        } else {
            setOtherCharges(parseFloat(additionalCharges));
        }
    }, [propertyOtherCharges, emiOption, cart, totalDue]);

    useEffect(() => {
        if (metadataLoaded) {
            calculateOtherCharges();
        }
    }, [metadataLoaded, calculateOtherCharges]);

    useEffect(() => {
        calculateOtherCharges();
    }, [emiOption, calculateOtherCharges]);

    const handleAddressSelect = async (address) => {
        setAddress(address);
        try {
            const results = await geocodeByAddress(address);
            if (results && results.length > 0) {
                const selectedAddress = results[0];
                setPinCode(
                    selectedAddress.address_components.find((comp) =>
                        comp.types.includes("postal_code")
                    )?.long_name || ""
                );
            }
        } catch (error) {
            console.log("Error fetching address details:", error);
        }
    };

    const handleCardTypeChange = (e) => {
        setCardType(e.target.value);
    };

    const handleSavedPaymentMethodChange = (e) => {
        setSavedPaymentMethod(e.target.value);
    };

    const handleAccountTypeChange = (e) => {
        setAccountType(e.target.value);
    };

    const handlePhoneNumberChange = (e) => {
        const formattedPhoneNumber = e.target.value;
        setPhoneNumber(formattedPhoneNumber);
        const rawPhoneNumber = formattedPhoneNumber.replace(/\D/g, "");
        setRawPhoneNumber(rawPhoneNumber);
    };

    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const isValidPhoneNumber = (phoneNumber) => /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(phoneNumber);

    const calculateNumberOfEmis = (startDate, dueDate, paymentDate, emiOption) => {
        const getNextPaymentDate = (date, day) => {
            const nextDate = new Date(date);
            nextDate.setDate(day);

            if (nextDate <= date) {
                nextDate.setMonth(nextDate.getMonth() + 1);
            }
            return nextDate;
        };

        let nextPaymentDate = getNextPaymentDate(startDate, paymentDate === "1st" ? 1 : 15);
        let currentYearPayments = 0;
        let nextYearPayments = emiOption;

        while (nextPaymentDate <= dueDate) {
            currentYearPayments++;
            nextPaymentDate = getNextPaymentDate(nextPaymentDate, paymentDate === "1st" ? 1 : 15);
        }

        currentYearPayments = Math.min(currentYearPayments, emiOption === "full" ? 1 : parseInt(emiOption, 10));

        const nextYearDueDate = new Date(dueDate);
        nextYearDueDate.setFullYear(dueDate.getFullYear() + 1);
        nextPaymentDate = getNextPaymentDate(nextYearDueDate, paymentDate === "1st" ? 1 : 15);

        return { currentYearPayments, nextYearPayments };
    };
    
    const calculatePaymentDates = useCallback(() => {
        const startDate = new Date();
        const dueDate = new Date(cart[0]?.due_date);
        const totalAmountDue = totalDue();
        const { currentYearPayments, nextYearPayments } = calculateNumberOfEmis(startDate, dueDate, paymentDate, emiOption);

        const currentYearSchedule = [];
        const nextYearSchedule = [];

        if (dueDate <= startDate) {
            setPaymentSchedule([]);
            return;
        }

        const taxYear = cart[0]?.tax_year || new Date().getFullYear(); // Use tax_year if available, otherwise fallback to current year

        const getNextPaymentDate = (date, day, period, next = false) => {
            const nextDate = new Date(date);
            nextDate.setDate(day);

            if (nextDate <= date) {
                if (next) {
                    nextDate.setMonth(nextDate.getMonth() + (period === 'current' ? 1 : Math.floor(12 / nextYearPayments) - 1));
                } else {
                    nextDate.setMonth(nextDate.getMonth() + (period === 'current' ? 1 : Math.floor(12 / nextYearPayments)));
                }
            }
            return nextDate;
        };

        let nextPaymentDate = getNextPaymentDate(startDate, paymentDate === "1st" ? 1 : 15, 'current');

        const amountPerEmiCurrentYear = totalAmountDue / currentYearPayments;

        for (let i = 0; i < currentYearPayments; i++) {
            const formattedDate = nextPaymentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
            const formattedAmount = `$${amountPerEmiCurrentYear.toFixed(2)}`;
            currentYearSchedule.push(`${formattedDate} - ${formattedAmount}`);

            if (i === 0) {
                setFirstPayment(`${nextPaymentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}: ${formattedAmount}`);
            }

            nextPaymentDate = getNextPaymentDate(nextPaymentDate, paymentDate === "1st" ? 1 : 15, 'current');
        }

        if (nextYearPayments > 0) {
            const amountPerNextYearEmi = totalAmountDue / nextYearPayments;
            nextPaymentDate = getNextPaymentDate(nextPaymentDate, paymentDate === "1st" ? 1 : 15, 'next', true);

            for (let i = 0; i < nextYearPayments; i++) {
                const formattedDate = nextPaymentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
                const extendedValue = amountPerNextYearEmi * (1 + parseFloat(nextYearExtendedValue) / 100);
                const formattedAmount = `$${extendedValue.toFixed(2)}`;

                if (i === nextYearPayments - 1) {
                    nextYearSchedule.push(`${formattedDate} - ${formattedAmount} *`);
                } else {
                    nextYearSchedule.push(`${formattedDate} - ${formattedAmount}`);
                }

                nextPaymentDate = getNextPaymentDate(nextPaymentDate, paymentDate === "1st" ? 1 : 15, 'next');
            }
        }

        // Use the dynamic tax year and tax year + 1
        setPaymentSchedule({
            [taxYear]: currentYearSchedule,
            [taxYear + 1]: nextYearSchedule,
        });
    }, [emiOption, paymentDate, cart, nextYearExtendedValue, totalDue]);


    useEffect(() => {
        calculatePaymentDates();
    }, [calculatePaymentDates]);

    const handleEMIChange = (event) => {
        setEmiOption(event.target.value);
    };

    const preventCopyPaste = (e) => {
        e.preventDefault();
        Swal.fire("Copying and pasting is not allowed!");
    };

    const fetchBankInformation = () => {
        setBankInfo(null);
        fetch(`${ROUTING_NUMBER_API_URL}/${routingNumber}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.message !== "bank not found") {
                    setBankInfo(data);
                }
            })
            .catch((error) => {
                console.error("Error fetching bank information:", error);
            });
    };

    const isFormValid = () => {
        let isValid = true;
        const newErrors = {
            email: "",
            phoneNumber: "",
            password: "",
            confirmPassword: "",
            paymentMethod: "",
        };

        if (!user) {
            if (!email) {
                newErrors.email = "Email is required.";
                isValid = false;
            } else if (!isValidEmail(email)) {
                newErrors.email = "Invalid email format.";
                isValid = false;
            }

            if (!phoneNumber) {
                newErrors.phoneNumber = "Phone number is required.";
                isValid = false;
            } else if (!isValidPhoneNumber(phoneNumber)) {
                newErrors.phoneNumber = "Invalid phone number format.";
                isValid = false;
            }

            if (!password) {
                newErrors.password = "Password is required.";
                isValid = false;
            } else if (password.length < 6) {
                newErrors.password = "Password must be at least 6 characters long.";
                isValid = false;
            }

            if (!confirmPassword) {
                newErrors.confirmPassword = "Confirm password is required.";
                isValid = false;
            } else if (password !== confirmPassword) {
                newErrors.confirmPassword = "Passwords do not match.";
                isValid = false;
            }
        }

        if (paymentMethod === "ach" && !savedPaymentMethod) {
            if (!accountNumber) {
                newErrors.accountNumber = "Account number is required.";
                isValid = false;
            }

            if (!repeatAccountNumber) {
                newErrors.repeatAccountNumber = 'Repeat Account number is required.';
                isValid = false;
            }

            if (accountNumber !== repeatAccountNumber) {
                newErrors.repeatAccountNumber = 'Account number & Repeat Account number must be same.';
                isValid = false;
            }

            if (!accountType) {
                newErrors.accountType = "Account type is required.";
                isValid = false;
            }


            /*
            if (!routingNumber) {
                newErrors.routingNumber = "Routing number is required.";
                isValid = false;
            } else if (!/^\d{9}$/.test(routingNumber)) {
                newErrors.routingNumber = "Invalid routing number format. It should be 9 digits.";
                isValid = false;
            } else if (!bankInfo) {
                newErrors.routingNumber = "Invalid routing number.";
                isValid = false;
            }
            */
        }

        if (!termsAgree || !smsTermsAgree || (paymentMethod === "ach" && !achAgree) || (paymentMethod === "creditCard" && !creditCardAgree)) {
            newErrors.termsAgree = 'Please check All terms checkboxes.';
            isValid = false;
        }


        const combinedErrorMessage = Object.values(newErrors)
            .filter((message) => message)
            .join("\n");

        if (!isValid) {
            Swal.fire({
                icon: "error",
                title: "Please fix the following issues:",
                html: combinedErrorMessage.replace(/\n/g, "<br>"),
                showCloseButton: true,
                showConfirmButton: true,
            });
        }

        return isValid;
    };

    const handleFormSubmit = () => {
        if (!isFormValid()) {
            return;
        }

        setLoading(true);

        fetch(`${API_URL}/billing-information/save-property`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                cartItems: cart,
                totalAmountDue: totalDue(),
                paymentMethod,
                accountNumber,
                repeatAccountNumber: accountNumber,
                accountName,
                routingNumber,
                accountType,
                cardType,
                transitTokenObject,
                achAgree,
                creditCardAgree,
                termsAgree,
                smsTermsAgree,
                rawPhoneNumber,
                email,
                phoneNumber,
                password,
                paymentDate,
                emiOption,
                paymentSchedule,
                propertyOtherCharges,
                address,
                pinCode,
                user,
                savedPaymentMethod
            }),
        })
            .then((response) => {
                setLoading(false);
                if (response.status === 422) {
                    return response.json().then((data) => {
                        const errorMessages = Object.values(data.errors).join("\n");
                        throw new Error(errorMessages);
                    });
                } else if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                login(data);

                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Congratulations! Your account has been successfully created.",
                    showConfirmButton: true,
                });

                clearCart();
                navigate("/account");
            })
            .catch((error) => {
                setLoading(false);
                const errorMessage = error.response?.data?.errors
                    ? Object.values(error.response.data.errors).join("<br>")
                    : error.message || "An unexpected error occurred";

                Swal.fire({
                    icon: "error",
                    title: "Error submitting the form",
                    html: errorMessage,
                    showCloseButton: true,
                    showConfirmButton: true,
                });
            });
    };

    useEffect(() => {
        window.tsepHandler = (eventType, event) => {
            if (eventType === "TokenEvent") {
                if (event.status === "FAIL") {
                    setValidationError((errors) => ({
                        ...errors,
                        cardNumber: event.message,
                    }));
                } else {
                    setTransitTokenObject(event);
                    setValidationError((errors) => ({
                        ...errors,
                        cardNumber: "",
                    }));
                }
            } else if (eventType === "FieldValidationErrorEvent") {
                const fieldErrors = {
                    "tsep-datepicker": "expiryDate",
                    "tsep-cvv2": "cvv",
                    "tsep-cardHolderName": "cardName",
                    "tsep-zipCode": "zipCode",
                    "tsep-cardNum": "cardNumber",
                };

                const fieldName = fieldErrors[event.fieldName];

                if (fieldName) {
                    setValidationError((errors) => ({
                        ...errors,
                        [fieldName]: event.message,
                    }));
                }
            }
        };

        fetch(`${API_URL}/generate-encrypted-manifest`)
            .then((response) => response.json())
            .then((data) => {
                const script = document.createElement("script");
                script.src = `${TSEP_HOST}/transit-tsep-web/jsView/${PAYMENT_GATEWAY_DEVICEID}?${data.encrypted_manifest}`;
                script.async = true;
                script.type = "text/javascript";

                script.addEventListener("load", () => {
                    if (window.TransIT && window.TransIT.processPayment) {
                        window.TransIT.processPayment();
                    }
                });

                const existingScript = document.querySelector(
                    `script[src="${script.src}"]`,
                );
                if (!existingScript) {
                    document.body.appendChild(script);
                }

                return () => {
                    document.body.removeChild(script);
                };
            })
            .catch((error) => {
                console.error("Error fetching manifest:", error);
            });
    }, [setTransitTokenObject]);

    const formatCurrency = value => `$${parseFloat(value).toFixed(2)}`;

    const maxNumPayments = 12;

    const handlePaymentMethodChange = (method) => {
        setPaymentMethod(method);
    };

    const [showExplanation, setShowExplanation] = useState(false); 
    const toggleExplanation = () => {
        setShowExplanation(!showExplanation); // Toggle the explanation on click
    };
  
    return (
        <div className="row payment-wrapper">
            {loading && <Loading text="Verifying Card..." />}
            <div className = "col-md-8">
                <div className="payment-sch-wrap">
                    <h3 className="text-left mt-3">Payment Schedule</h3>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="paymentDate">Payment Date</label>
                                <select
                                    id="paymentDate"
                                    name="paymentDate"
                                    className="form-control"
                                    onChange={(e) => setPaymentDate(e.target.value)}
                                    value={paymentDate}
                                    disabled={emiOption === "full"}
                                >
                                    <option value="1st">1st of every month</option>
                                    <option value="15th">15th of every month</option>
                                </select>
                            </div>
                            <div className="emi-opt">
                                <label>
                                    <input type="radio" name="emi" value="full" checked={emiOption === "full"} onChange={handleEMIChange} />
                                    Pay full
                                </label>
                                {maxNumPayments >= 4 && (
                                    <label>
                                        <input type="radio" name="emi" value="4" checked={emiOption === "4"} onChange={handleEMIChange} />
                                        Split into 4 payments
                                    </label>
                                )}
                                {maxNumPayments >= 6 && (
                                    <label>
                                        <input type="radio" name="emi" value="6" checked={emiOption === "6"} onChange={handleEMIChange} />
                                        Split into 6 payments
                                    </label>
                                )}
                                {maxNumPayments >= 12 && (
                                    <label>
                                        <input type="radio" name="emi" value="12" checked={emiOption === "12"} onChange={handleEMIChange} />
                                        Split into 12 payments
                                    </label>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            {emiOption !== "full" && (
                                <div className="payment-schedule">
                                    <h4>Payment Schedule for {cart[0]?.tax_year}</h4>
                                    <ul>
                                        {paymentSchedule[cart[0]?.tax_year]?.map((payment, index) => (
                                            <li key={index}><FontAwesomeIcon icon={faChevronRight} /> {payment}</li>
                                        ))}
                                    </ul>

                                    {paymentSchedule[cart[0]?.tax_year + 1]?.length > 0 && (
                                        <>
                                            <h4>
                                                Payment Schedule for {cart[0]?.tax_year + 1} 
                                                <span style={{ fontWeight: "bold" }}>
                                                    +{parseFloat(nextYearExtendedValue).toFixed(2)}% Estimated Tax Increase
                                                </span>
                                            </h4>
                                            <ul>
                                                {paymentSchedule[cart[0]?.tax_year + 1]?.map((payment, index) => (
                                                    <li key={index}><FontAwesomeIcon icon={faChevronRight} /> {payment}</li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </div>

                            )}
                        </div>
                    </div>
                </div>
                {!isAuthenticated ? (
                    <div className="cart-payment-wrap">
                        <p align="center" className="alert alert-warning cart-login-link">Already have an account? <span onClick={() => setLoginOpen(true)} style={{cursor: 'pointer', color: 'blue'}}>
                            Login here </span> 
                        </p>
                        <h3 className="text-left">Billing Information</h3>
                        <div className="form-group">
                            <label htmlFor="emailInput">Enter Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="emailInput"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phoneNumberInput">Phone Number</label>
                            <InputMask
                                mask="(999) 999-9999"
                                id="phoneNumberInput"
                                name="phoneNumber"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                className="form-control"
                                placeholder="Enter your phone number"
                                type="tel"
                                inputMode="numeric"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="passwordInput">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="passwordInput"
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirmPasswordInput">Confirm Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="confirmPasswordInput"
                                placeholder="Confirm your password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="logged-in-wrap">
                        <h3 className="text-left">Billing Information</h3>
                        <div className="logged-in-info">
                            <p>Logged in as <span className="user-name">{user?.name}</span></p>
                            <button className="btn btn-link logout-button" onClick={logout}>Logout</button>
                        </div>
                    </div>
                )}
            </div>
            <div className="col-md-4 cart-total-wrap">
                <h3 className="text-left">Payment Detail</h3>
                <div className="row">
                    <div className="col-md-12 payment-wrap">
                        {isAuthenticated && (
                            <div className="form-group">
                                <label htmlFor="tsep-cardType">Choose from Saved Method</label>
                                <select
                                    id="tsep-savedPaymentMethod"
                                    name="tsep-savedPaymentMethod"
                                    className="form-control"
                                    onChange={handleSavedPaymentMethodChange}
                                    value={savedPaymentMethod}
                                >
                                    <option value="">Create new method</option>
                                    {paymentMethods.map((method) => (
                                        <option key={method.id} value={method.id}>
                                            {method.card_holder_name} - {method.masked_card_number} ({method.payment_method})
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}

                        {(!isAuthenticated || savedPaymentMethod === "") && (
                            <>
                                <ul className="text-left payment-method-selection">
                                    <li className={`strip${paymentMethod === 'ach' ? ' active' : ''}`} onClick={() => handlePaymentMethodChange('ach')}>
                                        <FontAwesomeIcon icon={faCheck} className="completed-icon" />
                                    </li>
                                    <li className={`card${paymentMethod === 'creditCard' ? ' active' : ''}`} onClick={() => handlePaymentMethodChange('creditCard')}>
                                        <FontAwesomeIcon icon={faCheck} className="completed-icon" />
                                    </li>
                                </ul>
                                {paymentMethod === "creditCard" && (
                                    <div id="transit-fields" className="mt-4 clear-both form-cart">
                                        <div className="form-group">
                                            <label htmlFor="tsep-cardType">Card Type</label>
                                            <select
                                                id="tsep-cardType"
                                                name="tsep-cardType"
                                                className="form-control"
                                                autoComplete="cc-type"
                                                onChange={handleCardTypeChange}
                                                value={cardType}
                                            >
                                                <option value="">Select Card Type</option>
                                                <option value="CREDIT">Credit Card</option>
                                                <option value="DEBIT">Debit Card</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="tsep-cardNum">Card Number</label>
                                            <input
                                                id="tsep-cardNum"
                                                name="tsep-cardNum"
                                                className="form-control"
                                                autoComplete="cc-number"
                                                maxLength="23"
                                                type="tel"
                                            />
                                            {validationError.cardNumber && (
                                                <span className="error">{validationError.cardNumber}</span>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label htmlFor="tsep-datepicker">Expiration Date</label>
                                                    <input
                                                        id="tsep-datepicker"
                                                        name="tsep-datepicker"
                                                        className="form-control"
                                                        placeholder="MM/YYYY"
                                                        autoComplete="cc-exp"
                                                        maxLength="7"
                                                        type="tel"
                                                    />
                                                    {validationError.expiryDate && (
                                                        <span className="error">{validationError.expiryDate}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="tsep-cvv2">CVV</label>
                                                    <input
                                                        id="tsep-cvv2"
                                                        name="tsep-cvv2"
                                                        className="form-control"
                                                        placeholder="CVV"
                                                        autoComplete="off"
                                                        maxLength="4"
                                                        type="text"
                                                    />
                                                    {validationError.cvv && (
                                                        <span className="error">{validationError.cvv}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="tsep-cardHolderName">Name on Card</label>
                                            <input
                                                id="tsep-cardHolderName"
                                                name="tsep-cardHolderName"
                                                className="form-control"
                                                autoComplete="cc-name"
                                                maxLength="30"
                                                type="text"
                                            />
                                            {validationError.cardName && (
                                                <span className="error">{validationError.cardName}</span>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="tsep-zipCode">Zip Code</label>
                                            <input
                                                id="tsep-zipCode"
                                                name="tsep-zipCode"
                                                className="form-control"
                                                autoComplete="billing postal-code"
                                                maxLength="10"
                                                type="text"
                                            />
                                            {validationError.zipCode && (
                                                <span className="error">{validationError.zipCode}</span>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {paymentMethod === "ach" && (
                                    <div className="mt-4 clear-both form-cart">
                                        <div className="form-group">
                                            <label htmlFor="accountName">Name on Account</label>
                                            <input
                                                id="accountName"
                                                name="accountName"
                                                className="form-control"
                                                type="text"
                                                onChange={(e) => setAccountName(e.target.value)}
                                            />
                                            <small id="name_help" className="form-text text-muted">Customer's Name as it appears on the bank account and signor on the account.</small>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="accountNumber">Account Number</label>
                                            <input
                                                id="accountNumber"
                                                name="accountNumber"
                                                className="form-control"
                                                type={accountNumberType}
                                                onFocus={() => setAccountNumberType('tel')}
                                                onBlur={() => setAccountNumberType('password')}
                                                onChange={(e) => setAccountNumber(e.target.value)}
                                                onCopy={preventCopyPaste}
                                                onPaste={preventCopyPaste}
                                                onCut={preventCopyPaste}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="repeatAccountNumber">Repeat Account Number</label>
                                            <input
                                                id="repeatAccountNumber"
                                                name="repeatAccountNumber"
                                                className="form-control"
                                                type={repeatAccountNumberType}
                                                onFocus={() => setRepeatAccountNumberType('tel')}
                                                onBlur={() => setRepeatAccountNumberType('password')}
                                                onChange={(e) => setRepeatAccountNumber(e.target.value)}
                                                onCopy={preventCopyPaste}
                                                onPaste={preventCopyPaste}
                                                onCut={preventCopyPaste}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="routingNumber">Routing Number</label>
                                                    <input
                                                        id="routingNumber"
                                                        name="routingNumber"
                                                        className="form-control"
                                                        type="tel"
                                                        onChange={(e) => setRoutingNumber(e.target.value)}
                                                        onBlur={fetchBankInformation}
                                                    />
                                                    {bankInfo && (
                                                        <div className="bank-info">
                                                            <p>{bankInfo.bank}</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="accountType">Account Type</label>
                                                    <select
                                                        id="accountType"
                                                        name="accountType"
                                                        className="form-control"
                                                        onChange={handleAccountTypeChange}
                                                        value={accountType}
                                                    >
                                                        <option value="">Select Account Type</option>
                                                        <option value="Checking">Checking</option>
                                                        <option value="Savings">Savings</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <PlacesAutocomplete
                                                        value={address}
                                                        onChange={setAddress}
                                                        onSelect={handleAddressSelect}
                                                    >
                                                        {({
                                                            getInputProps,
                                                            suggestions,
                                                            getSuggestionItemProps,
                                                            loading,
                                                        }) => (
                                                            <div className="count-wrap-div">
                                                                <input
                                                                    className="form-control"
                                                                    {...getInputProps({
                                                                        placeholder: "Enter your address",
                                                                    })}
                                                                />
                                                                {suggestions.length > 0 && (
                                                                    <div className="count-inner">
                                                                        {loading && <div>Loading...</div>}
                                                                        {suggestions.map((suggestion, index) => {
                                                                            const style = {
                                                                                backgroundColor: suggestion.active
                                                                                    ? "#0c4adb"
                                                                                    : "",
                                                                            };

                                                                            return (
                                                                                <div
                                                                                    key={index}
                                                                                    {...getSuggestionItemProps(suggestion, {
                                                                                        style,
                                                                                    })}
                                                                                >
                                                                                    {suggestion.description}
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <table className="total-amt" width="100%">
                    <tbody>
                        <tr>
                            <td width="60%">Items in Cart</td>
                            <td width="40%" className="text-right"><b>{formatCurrency(totalDue())}</b></td>
                        </tr>
                        {emiOption !== 'full' && <tr><td>First Payment On</td><td className="text-right"><b>{firstPayment}</b></td></tr>}

                        <tr className="total">
                            <td>Due Today:</td>
                            <td className="text-right"><b>{formatCurrency(otherCharges)}</b></td>
                        </tr>
                    </tbody>
                </table>

                {paymentMethod === "ach" && (
                    <div className="form-group">
                        <div className="alert alert-warning">
                            <label>
                                By agreeing to these terms, you authorize Renew My Technologies to debit your bank account for the property tax payment via ACH via the schedule selected. You will receive advance notice and can cancel up to three days before the debit. Your agreement confirms your acceptance.
                            </label>
                            <div className="checkbox">
                                <label>
                                    <input type="checkbox" name="ach_agree" checked={achAgree} onChange={(e) => setAchAgree(e.target.checked)} />
                                    <span className="terms">I Acknowledge and Consent to the Terms of Authorization for Annual ACH Debit by Renew My Technologies.</span>
                                </label>
                            </div>
                        </div>
                    </div>
                )}

                {paymentMethod === "creditCard" && (
                    <div className="form-group">
                        <div className="alert alert-warning">
                            <label>
                                By agreeing to these terms, you authorize Renew My Technologies to annually debit your bank account for vehicle plate renewal costs via ACH. You will receive advance notice and can cancel up to three days before the debit. Your agreement confirms your acceptance.
                            </label>
                            <div className="checkbox">
                                <label>
                                    <input type="checkbox" name="creditCard_agree" checked={creditCardAgree} onChange={(e) => setCreditCardAgree(e.target.checked)} />
                                    <span className="terms">I Acknowledge and Consent to the Terms of Authorization for Annual Charge by Renew My Technologies.</span>
                                </label>
                            </div>
                        </div>
                    </div>
                )}

                <div className="form-group termncondition">
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" name="terms_agree" checked={termsAgree} onChange={(e) => setTermsAgree(e.target.checked)} />
                            <span className="terms">
                                I agree to the {" "}
                                <a href="/terms/use" target="_blank">Terms and Conditions</a>.
                            </span>
                        </label>
                    </div>
                </div>

                <div className="form-group termncondition">
                    <div className="checkbox">
                        <label>
                            <input type="checkbox" name="sms_terms_agree" checked={smsTermsAgree} onChange={(e) => setSmsTermsAgree(e.target.checked)} />
                            <span className="terms">I agree to receive SMS and Email notifications according to the{" "} <a href="/terms/use" target="_blank">Terms and Conditions</a>.</span>
                        </label>
                    </div>
                </div>

                <div className="text-left submit-wrap">
                    <button type="button" className="btn btn-primary" onClick={handleFormSubmit} disabled={loading}>Signup</button>
                </div>
            </div>
            <LoginPopup isOpen={isLoginOpen} onRequestClose={() => setLoginOpen(false)} onSuccessRedirect="/cart" />
        </div>
    );
}

export default PaymentForm;
